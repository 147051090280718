import {
  FunctionComponent,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from "react"
import { Item, MuteType } from "../../types"
import ItemPreview from "../item/preview"
import { useItems } from "../../hooks/use-items"
import { useFilters } from "../../hooks/use-filters"
import { GET_items, GET_likes } from "../../services/http"
import React from "react"
import { convertContentItems } from "../../services/content"
import { useUser } from "../../hooks/use-user"
import { useIndexedDB } from "../../hooks/use-indexeddb"
import { useScroll } from "../../hooks/use-scroll"
import { useUpdate } from "../../hooks/use-update"

export type FeedInfo = {
  feed: number
  reset: boolean
  scroll: number
  filters: string
  ts: number
  kind: string
}

export type ScrollEvent = {
  feed: number
  ts: number
}

type Props = {
  feedId: number
  currentFeed: FeedInfo
  scroll: ScrollEvent
  initialChannelIds: string[]
  showFeed: (info: FeedInfo) => void
  comment: (item: Item) => void
  showDonations: (item: Item) => void
}

const CachedFeed: FunctionComponent<Props> = ({
  feedId,
  currentFeed,
  scroll,
  initialChannelIds,
  showFeed,
  comment,
  showDonations,
}) => {
  const { items, updateItem, addItems, likeItem, setItems } = useItems([])
  const { getKey, getLikedItems, muteItems } = useIndexedDB()

  const { filters } = useFilters()
  const { loadItemLikes, updateItemLikes } = useUser()
  const [olderNotice, setOlderNotice] = useState<string | null>(null)

  const fetchPage = useCallback(
    async (offset: number) => {
      if (filters.liked == false) {
        /*console.log(
          "Feed " + feedId + " fetchPage " + offset + " " + filters.channel_ids
        )*/
        const response = await Promise.resolve(
          // TODO: prevent requests if no data
          //fetchItems(offset)
          GET_items({
            count: 10,
            offset: 10 * offset,
            q: filters.q,
            sort: filters.sort,
            age: filters.age,
            languages: filters.languages,
            countries: filters.countries,
            channel_ids: filters.channel_ids,
            tags: filters.tags,
            filter:
              initialChannelIds.length || !filters.moderated
                ? 0
                : filters.bitmask,
            nc: filters.nc,
            mod: filters.mod,
            // TODO: tags (parse q before sending?)
          })
        )
        if (response?.hits?.hits.length) {
          let items = convertContentItems(response, filters)
          return items
        }
      } else {
        console.log("fetchLikedPage")
        const response = await Promise.resolve(
          getLikedItems(10 * offset, 10, filters)
        )
        if (response && response.length) {
          return response
        } else return []
      }
    },
    [filters]
  )

  const add = useCallback(
    (newItems: Item[], offset: number) => {
      if (newItems) {
        /*console.log(feedId + " " + offset + " " + newItems)
        for (var i = 0; i < newItems.length; i++) {
          console.log(
            newItems[i].amount +
              " " +
              newItems[i]["domain"] +
              " " +
              newItems[i].ts
          )
        }*/
        if (offset == 0) {
          if (items.length > 0) {
            setItems([])
          }
          if (newItems.length && typeof newItems[0].older !== "undefined") {
            let p =
              newItems[0].older == 168
                ? "week"
                : newItems[0].older == 720
                ? "month"
                : newItems[0].older == 8760
                ? "year"
                : "all"
            setOlderNotice(p)
          } else {
            setOlderNotice(null)
          }
        }
        muteAndAdd(newItems)
      }

      //console.log("feeds:: add " + offset + " " + currentFeed.feed + " " + currentFeed.reset)
      if (offset == 0 && feedId == currentFeed.feed) showFeed(currentFeed)
      // TODO: trigger single rererender
    },
    [addItems, items, currentFeed, feedId, showFeed]
  )

  const { resetLoader, onScroll } = useScroll<Item>(items, 10, {
    fetchPage,
    add,
  })

  const getContent = useCallback((items: Item[]) => {
    const content: Item[] = items
    return content
  }, [])

  const content = useMemo(() => getContent(items), [items.length, filters])

  useEffect(() => {
    //console.log("currentFeed changed " + feedId + " " + currentFeed.feed)
    if (feedId == currentFeed.feed) {
      //console.log("feeds:: wake up " + currentFeed.feed + " " + currentFeed.reset)
      if (currentFeed.reset) resetLoader()
      else {
        showFeed(currentFeed)
      }
    }
  }, [currentFeed])

  const scrollIfCurrent = useCallback(async () => {
    //console.log("onscroll " + feedId + " " + currentFeed.feed)
    if (feedId == currentFeed.feed) {
      //console.log("scrolling " + feedId + " " + currentFeed.feed)
      onScroll()
    }
  }, [currentFeed, onScroll])

  useEffect(() => {
    scrollIfCurrent()
  }, [scroll])

  const muteAndAdd = useCallback(
    async items => {
      if (filters.liked) {
        let ids: string[] = []
        let urls: string[] = []
        for (var i = 0; i < items.length; i++) {
          //console.log(items[i].url)
          if ("id" in items[i]) {
            /*ids.push(items[i].id)*/
          } else urls.push(items[i].url)
        }
        if (ids.length > 0 || urls.length > 0) {
          const response = await Promise.resolve(
            GET_likes({
              ids: ids.length > 0 && ids.join(","),
              urls: urls.length > 0 && urls.join(","),
            })
          )
          if (response?.hits?.hits.length) {
            let new_items = convertContentItems(response, filters)
            for (var i = 0; i < items.length; i++) {
              for (var j = 0; j < new_items.length; j++) {
                if (items[i].url == new_items[j].url) {
                  /*console.log("found " + items[i].url)
                  console.log(items[i])
                  console.log(new_items[j])*/
                  updateItemLikes(new_items[j], 0)
                  items[i] = {
                    ...new_items[j],
                    amount: items[i].amount,
                    added_date: items[i].added_date,
                  }
                  break
                }
              }
            }
          }
        }
      }
      muteItems(items, addItems)
      loadItemLikes(items, filters.liked)
    },
    [getKey, filters, loadItemLikes, filters.liked]
  )

  const onMute = useCallback(
    (id: string, kind: MuteType) => {
      if (kind == MuteType.Creator)
        setItems(items =>
          items.filter(item => item.creator == null || item.creator.id !== id)
        )
      else if (kind == MuteType.Domain)
        setItems(items =>
          items.filter(
            item => item.publisher == null || item.publisher.id !== id
          )
        )
      else if (kind == MuteType.Owner)
        setItems(items =>
          items.filter(
            item =>
              (item.creator == null || item.creator.owner !== id) &&
              (item.publisher.owner == null || item.publisher.owner !== id)
          )
        )
      else if (kind == MuteType.Item)
        setItems(items => items.filter(item => item.id !== id))
    },
    [setItems]
  )

  const { updateFlag, setUpdateFlag } = useUpdate()
  useEffect(() => {
    if (feedId == currentFeed.feed && updateFlag == true) {
      console.log(
        "CachedFeed ======================== componentDidMount " + updateFlag
      )
      setUpdateFlag(false)
      resetLoader()
    }
  }, [updateFlag])

  return (
    <>
      <div>
        <div>
          <ul>
            {olderNotice && (
              <div className="olderData">
                Showing the chart for older period - {olderNotice}
              </div>
            )}
            {items.map(x => (
              <li key={x.id}>
                <ItemPreview
                  liked={filters.liked}
                  item={x}
                  onLike={likeItem}
                  onUpdate={updateItem}
                  onMute={onMute}
                  onShowComments={comment}
                  onShowDonations={showDonations}
                />
              </li>
            ))}
          </ul>
        </div>

        {/*isLoading ? (
          <div className="loader">
            <Loader loading={isLoading} />
          </div>
        ) : (
          !content.length &&
          !isContentAvailable &&
          ((filters.tags && filters.tags.length) || filters.liked) && (
            <p>Reset filters?</p>
          )
          )*/}

        <style jsx>
          {`
            ul {
              list-style: none;
              padding: 0;
              margin: 0;
            }
            li:not(:first-child) {
              margin-top: 1.5em;
            }
            li {
              margin-bottom: 1.5em;
            }
            p {
              margin-top: 100px;
              padding: 20px;
              text-align: center;
            }
            .olderData {
              padding-left: 5px;
            }
          `}
        </style>
      </div>
    </>
  )
}

export default CachedFeed
