import { useCallback, useState } from "react"
import { unstable_batchedUpdates } from "react-dom"
import { useLoader } from "./use-loader"

export const useScroll = <T>(
  items: T[],
  count: number,
  actions: {
    fetchPage: (offset: number) => Promise<T[] | undefined>
    add: (xs: T[], offset: number) => void
  }
) => {
  const [offset, setOffset] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [isContentAvailable, setContentAvailable] = useState(true)
  const [loadTime, setLoadTime] = useState(0)

  const loadMore = useCallback(
    async (reset: boolean) => {
      /*console.log(
        "loadMore " +
          isContentAvailable +
          " " +
          isLoading +
          " " +
          offset +
          " " +
          reset
      )*/
      if (isLoading) return

      let currentOffset = offset
      if (reset) {
        currentOffset = 0
        setContentAvailable(true)
      } else if (!isContentAvailable) return

      setLoading(true)
      const data = await actions.fetchPage(currentOffset)
      if (data?.length) {
        if (data?.length == count) {
          unstable_batchedUpdates(() => {
            actions.add(data, currentOffset)
            setOffset(currentOffset + 1)
            setLoading(false)
            setLoadTime(new Date().getTime())
          })
        } else {
          unstable_batchedUpdates(() => {
            actions.add(data, currentOffset)
            setContentAvailable(false)
            setOffset(currentOffset + 1)
            setLoading(false)
            setLoadTime(new Date().getTime())
          })
        }
      } else {
        unstable_batchedUpdates(() => {
          setContentAvailable(false)
          setLoading(false)
          setLoadTime(new Date().getTime())
        })
      }
    },
    [isLoading, isContentAvailable, actions.fetchPage, actions.add, offset]
  )

  useLoader(isLoading)

  const onScroll = useCallback(async () => {
    const isTriggered =
      window.scrollY + window.innerHeight >=
      document.body.scrollHeight - window.innerHeight / 2

    if (isTriggered && !isLoading && isContentAvailable) {
      setLoading(true)
      loadMore(false)
    }
  }, [loadMore, isLoading, isContentAvailable])

  const resetLoader = useCallback(async () => {
    loadMore(true)
  }, [loadMore])

  return { isLoading, loadTime, isContentAvailable, resetLoader, onScroll }
}
