import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { AnyMxRecord } from "dns"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useMedia } from "../../hooks/use-media"
import { useStyle } from "../../hooks/use-style"
import { getCachedSecret, useUser } from "../../hooks/use-user"
import { rpc } from "../../services/node"
import { darkTheme, lightTheme } from "../../services/theme"
import { Gateway } from "../../types"
import SubmitButton from "../layout/form/submit-button"
import { showToast } from "../status-notification"

type Props = {
  channel: AnyMxRecord
  close: (account: any) => void
  gateways: Gateway[]
}

/*
const test_gateways = [
  { address: "a1@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a2@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a3@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a4@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a5@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a6@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a7@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a8@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a9@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a0@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
  { address: "a10@b.c", processor: "PayPal", currency: "EUR", country: "AUT" },
]
*/

export const SelectGateway: FunctionComponent<Props> = ({
  channel,
  close,
  gateways,
}) => {
  const { user, scheduleBalanceQuery } = useUser()
  const { isDesktopLayout } = useMedia()
  const [selectedGateway, setSelectedGateway] = useState<Gateway | null>(
    gateways.length == 1 ? gateways[0] : null
  )
  const isSelected = (address: string) =>
    selectedGateway != null && selectedGateway.address == address

  const [targetCurrency, setTargetCurrency] = useState("USD")
  useEffect(() => {
    if (user && user.balance && user.balance.currency)
      setTargetCurrency(user.balance.currency)
  }, [user])

  //console.log(channel)
  const css = useStyle(theme => ({
    buttonBackgroundColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    inactiveButtonBackgroundColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    buttonTextColor: "black",
    inactiveButtonTextColor: "gray",

    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const selectGateway = useCallback(
    async (gw: Gateway) => {
      console.log("gateway selected " + gw.address)
      setSelectedGateway(gw)
    },
    [gateways]
  )

  const transfer = useCallback(
    (gw: Gateway) => {
      if (gw.processor.indexOf("PayPal") != -1) {
        const name =
          channel.kind == 2
            ? channel.alias_name + "#" + channel.alias_host
            : channel.id

        let url =
          "https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=" +
          encodeURIComponent(gw.address) +
          "&invoice=" +
          channel.owner +
          "." +
          Math.floor(Date.now()).toString() +
          "&item_name=" +
          encodeURIComponent("Donate to " + name) +
          "&item_number=" +
          channel.owner +
          "&custom=" +
          user?.pk +
          "%20" +
          channel.id +
          "%20" +
          targetCurrency

        console.log(url)
        window.open(url, "_blank")
      } else {
        const msg = {
          pk: "", // will be set downstream
          recipient: channel.owner,
          channel_id: channel.id,
          alias: channel.kind == 2 ? channel.alias : channel.id,
          target_currency: targetCurrency,
        }
        console.log(JSON.stringify(msg))
        console.log(JSON.stringify(channel))
        rpc(
          "test_donate",
          JSON.stringify(msg),
          user,
          getCachedSecret(),
          function (res) {
            if (res.status == "ok") {
              console.log("Donating successful", res)
              showToast(faInfoCircle, "Smartlike network", "Test donation sent")
            } else console.error("Donating failed")
          }
        )
      }
      scheduleBalanceQuery(5)
      close(null)
    },
    [channel, user, close, targetCurrency]
  )

  interface Column {
    id: "address" | "processor" | "currency" | "country"
    label: string
    minWidth?: number
    align?: "right"
    format?: (value: number) => string
  }

  const columns: Column[] = [
    { id: "address", label: "address", minWidth: 100 },
    { id: "processor", label: "processor", minWidth: 100 },
    { id: "currency", label: "currency", minWidth: 50 },
    { id: "country", label: "country", minWidth: 50 },
  ]

  const aliases =
    channel.aliases && channel.aliases.length
      ? channel.aliases.map(a => a.alias).join(" ,")
      : ""

  return (
    <div style={{ maxWidth: "600px", fontSize: "14px" }}>
      <section>
        <div style={{ float: "right", color: "grey" }}>
          <div
            onClick={() => {
              close(null)
            }}
            className="close"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </div>
        </div>

        <div>
          {channel.name && channel.name.length ? (
            <>
              <h1>
                Donate to {channel.title}&nbsp;
                <span className="handle">@{channel.name}</span>
              </h1>
              <div
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                <p>
                  You are about to donate to account <b>{channel.id}</b>.
                  {aliases.length != 0 && (
                    <>
                      {" "}
                      It has been verified to own the following aliases:{" "}
                      {aliases}.
                    </>
                  )}
                </p>
                <p>
                  Please note that it's a direct transfer: Smartlike will only
                  receive a notification. Should there be any problems with the
                  transfer, please contact the recipient.
                </p>
              </div>
            </>
          ) : (
            <>
              <h1>Donate to {channel.id}</h1>
              <div
                style={{
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                <p>
                  You are about to donate to account <b>{channel.owner}</b>. It
                  has been verified to own <b>{channel.id}</b>.
                </p>
                <p>
                  Please note that it's a direct transfer: Smartlike will only
                  receive a notification. Should there be any problems with the
                  transfer, please contact the recipient.
                </p>
              </div>
            </>
          )}

          {gateways.length == 1 ? (
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div>
                <div className="cell-1">Processor:</div>
                <div className="cell-1">Currency:</div>
                <div className="cell-1">Country:</div>
                <div className="cell-1">Address:</div>
              </div>
              <div>
                <div className="cell-1">{gateways[0].processor}</div>
                <div className="cell-1">{gateways[0].currency}</div>
                <div className="cell-1">{gateways[0].country}</div>
                <div className="cell-1">{gateways[0].address}</div>
              </div>
            </div>
          ) : (
            <div className="table-container">
              <div className="table">
                {gateways.map(row => {
                  const isItemSelected = isSelected(row.address)
                  return (
                    <div
                      key={row.address}
                      className="donation-address"
                      onClick={() => selectGateway(row)}
                    >
                      <div
                        style={{}}
                        className={isItemSelected ? "cell selected" : "cell"}
                      >
                        {row.processor}
                      </div>
                      <div
                        style={{}}
                        className={isItemSelected ? "cell selected" : "cell"}
                      >
                        {row.currency}
                      </div>
                      <div
                        style={{}}
                        className={isItemSelected ? "cell selected" : "cell"}
                      >
                        {row.country}
                      </div>
                      {isDesktopLayout && (
                        <div
                          style={{}}
                          className={isItemSelected ? "cell selected" : "cell"}
                        >
                          {row.address}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>

        <div className="controls">
          <SubmitButton
            onClick={() => transfer(selectedGateway)}
            disabled={selectedGateway == null}
          >
            Next
          </SubmitButton>
        </div>
      </section>
      <style jsx>{`
      .table-container {
        margin-top: 20px;
        max-height: 150px;
        overflow-y: scroll;

        background-color: ${css.backgroundColor};
        border: 1px solid ${css.borderColor};
        transition: 200ms background-color, 200ms border-color,
          200ms box-shadow;
        border-radius: 0.4em;
        
      }
      .table {
        display: table;
        width: 100%;

      }
      .donation-address {
        display: table-row;
      }
      .handle {
        color: rgb(101, 119, 134);
        margin-left: 4px;
      }
      
      .close {
        font-size: 14px;
      }
      .close:hover {
        cursor: pointer;
      }
      
      .controls {
        font-size: 14px;
        text-align: right;
        margin-top: 20px;        
      }
      section {
        padding: 1em 1em 0.5em;
      }
      h1 {
        font-size: 16px;
        font-weight: 500;
      }
      button {
        border: none;
        border-radius: 0.3em;
        background-color: ${css.buttonBackgroundColor};
        transition: 200ms background-color, 200ms color;
        padding: 0.5em 1em;
        color: black;
        min-width: 7.5em;
    }
    button:disabled {
        color: ${css.inactiveButtonTextColor};
    }

        .root {
          width: 100%;
        }
        .container {
          /*maxheight: 440px;*/
          /*border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
          border-radius: 0.3em;*/
        }
        .separator {
          width: 100%;
          border-bottom: 1px solid #eeeeee;
          margin-top: 5px;
          margin-bottom: 10px;
        }
        .row {
          display: table;
          width: 100%;
        }
        .row:hover {
          background-color: #eee;
          cursor: pointer;
        }
        .selected {
          background-color: #eee;
        }

        .cell {
          padding: 6px;
          display: table-cell;
        }
        .cell-1 {
          padding: 6px;
        }

        .tableheader {
          width: 100%;
          display: table;
          color: rgba(0, 0, 0, 0.54);
          padding-left: 10px;
          padding-right: 10px;
        }
        .table {
          width: 100%;
        }
        .btn {
          width: 100px;
          margin-right: 10px;
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#eee",
      },
    },
    tableCell: {
      "$selected &": {
        color: "#eee",
      },
    },
    selected: {},
  })
)
